import React from 'react';

import './../style.scss';

const ShapesComponent = (props) => (
  <svg
    width={422}
    height={282}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="up"
      fill="#3C3838"
      d="m173.484 223.429 53.517-25.34 1.426 3.012-53.517 25.34zM176.543 229.89l53.517-25.34 1.426 3.012-53.517 25.34zM180.039 237.273l53.517-25.34 1.426 3.012-53.517 25.34zM183.099 243.734l53.517-25.34 1.426 3.012-53.517 25.34zM186.158 250.195l53.517-25.34 1.426 3.012-53.517 25.34zM189.217 256.656l53.517-25.34 1.426 3.012-53.517 25.34zM192.713 264.04l53.517-25.34 1.426 3.012-53.517 25.34z"
    />
    <path
      d="M396.233 238.697c2.682 5.544-1.642 11.912-7.784 11.463l-31.562-2.307c-6.142-.449-9.494-7.378-6.035-12.473l17.779-26.179c3.459-5.095 11.137-4.534 13.819 1.01l13.783 28.486Z"
      fill="#4F8FC8"
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={349}
      y={205}
      width={49}
      height={46}
    >
      <path
        d="M396.233 238.697c2.682 5.544-1.642 11.912-7.784 11.463l-31.562-2.307c-6.142-.449-9.494-7.378-6.035-12.473l17.779-26.179c3.459-5.095 11.137-4.534 13.819 1.01l13.783 28.486Z"
        fill="#65B0E6"
      />
    </mask>
    <g mask="url(#a)" fill="#ABD5D4" fillOpacity={0.6}>
      <path d="m357.105 194.208 53.593 25.178-1.417 3.017-53.593-25.178zM354.065 200.678l53.593 25.178-1.417 3.017-53.593-25.178zM350.591 208.072l53.593 25.178-1.417 3.017-53.593-25.178zM347.552 214.542l53.593 25.178-1.417 3.017-53.593-25.178zM344.512 221.013l53.593 25.178-1.417 3.017-53.593-25.178zM341.472 227.483l53.593 25.178-1.417 3.017-53.593-25.178zM337.999 234.877l53.593 25.178-1.417 3.017-53.593-25.178z" />
    </g>
    <path
      className="rotatingNeg"
      d="M390.12 236.255c2.012 4.158-1.232 8.934-5.838 8.598l-31.562-2.307c-4.606-.337-7.121-5.534-4.526-9.355l17.778-26.179c2.595-3.821 8.353-3.401 10.365.757l13.783 28.486Z"
      stroke="#3C3838"
      strokeWidth={4}
    />
    <rect
      x={248.172}
      width={52}
      height={52}
      rx={8}
      transform="rotate(11.28 248.172 0)"
      fill="#477C30"
    />
    <mask
      id="b"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={239}
      y={1}
      width={59}
      height={59}
    >
      <rect
        x={248.172}
        width={52}
        height={52}
        rx={8}
        transform="rotate(11.28 248.172 0)"
        fill="#38835F"
      />
    </mask>
    <g mask="url(#b)" fill="#6C7A26" fillOpacity={0.8}>
      <path d="m234.692 14.399 69.399-9.157.436 3.305-69.399 9.156zM235.628 21.486l69.399-9.157.436 3.305-69.399 9.156zM233.923 7.278l69.399-9.157.436 3.305-69.399 9.156zM236.696 29.585l69.399-9.157.436 3.305-69.399 9.156zM237.631 36.672l69.399-9.157.435 3.305-69.398 9.156zM238.566 43.76l69.399-9.157.436 3.305-69.399 9.156zM239.501 50.847 308.9 41.69l.436 3.305-69.399 9.156zM240.57 58.946l69.399-9.157.436 3.305-69.399 9.156z" />
    </g>
    <rect
      className="rotating"
      x={254.742}
      y={9.353}
      width={48}
      height={48}
      rx={6}
      transform="rotate(11.28 254.742 9.353)"
      stroke="#3C3838"
      strokeWidth={4}
    />
    <circle cx={191} cy={134} r={26} fill="#E4AB08" />
    <mask
      id="c"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={165}
      y={108}
      width={52}
      height={52}
    >
      <circle cx={191} cy={134} r={26} fill="#F5D580" />
    </mask>
    <g mask="url(#c)" fill="#D6C344" fillOpacity={0.8}>
      <path className="rotatingNeg" d="m166.252 104.248 58.869 6.373-.359 3.314-58.869-6.373zM165.718 112.041l58.869 6.373-.359 3.314-58.869-6.373zM164.774 119.974l58.869 6.373-.359 3.314-58.869-6.373zM164.241 127.767l58.869 6.373-.359 3.314-58.869-6.373zM163.707 135.559l58.869 6.373-.359 3.314-58.869-6.373zM163.173 143.352l58.869 6.373-.359 3.314-58.869-6.373zM162.229 151.286l58.869 6.373-.359 3.314-58.869-6.373z" />
    </g>
    <circle cx={185} cy={129} r={24} stroke="#3C3838" strokeWidth={4} />
    <path
      className="up"
      d="M33.797 102.354c1.072-6.065 8.306-8.695 13.022-4.734l24.234 20.35c4.716 3.961 3.377 11.541-2.41 13.645L38.9 142.427c-5.788 2.104-11.683-2.846-10.611-8.911l5.507-31.162Z"
      fill="#D05F4D"
    />
    <mask
      id="d"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={28}
      y={95}
      width={46}
      height={48}
    >
      <path
        className="up"
        d="M33.797 102.354c1.072-6.065 8.306-8.695 13.022-4.734l24.234 20.35c4.716 3.961 3.377 11.541-2.41 13.645L38.9 142.427c-5.788 2.104-11.683-2.846-10.611-8.911l5.507-31.162Z"
        fill="#D05F4D"
      />
    </mask>
    <g mask="url(#d)" fill="#D6867A" fillOpacity={0.8}>
      <path d="M8 124.87 49.87 83l2.357 2.357-41.87 41.87zM13.055 129.925l41.87-41.87 2.357 2.357-41.87 41.87zM18.832 135.702l41.87-41.87 2.357 2.357-41.87 41.87zM23.887 140.757l41.87-41.87 2.357 2.357-41.87 41.87zM28.941 145.811l41.87-41.87 2.357 2.357-41.87 41.87zM33.996 150.866l41.87-41.87 2.357 2.357-41.87 41.87zM39.773 156.643l41.87-41.87L84 117.13 42.13 159z" />
    </g>
    <path
    className="rotatingNeg"
      d="M31.766 110.702c.804-4.548 6.23-6.521 9.767-3.551l24.234 20.351c3.537 2.97 2.533 8.656-1.808 10.234l-29.741 10.812c-4.341 1.578-8.763-2.135-7.959-6.684l5.507-31.162Z"
      stroke="#000"
      strokeWidth={4}
    />
  </svg>
)

export { ShapesComponent }
